$( window ).on( "load", function() {
    var routes = [];
    $.getJSON( "/api/routes", function( datas, status, xhr ) {
        datas.forEach(data => routes.push(data.nom));
      });
    $("#recherches")
    .on( "keydown", function( event ) {
        if ( event.keyCode === $.ui.keyCode.TAB &&
            $( this ).autocomplete( "instance" ).menu.active ) {
          event.preventDefault();
        }
      })
    .autocomplete({
      minLength: 0,
      position:{ my: "left bottom", at: "left top", collision: "flip" },
      source: function( request, response ) {
        response( $.ui.autocomplete.filter(
            routes, request.term ) );
      },
      select: function( event, ui ) {
          window.location.href = window.location.origin + "/change/route/" + ui.item.value;
          return false;
      }
    });
  } );