/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/global.scss');
require("@fortawesome/fontawesome-free/js/all.js");

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $  = require( 'jquery' );
window.jQuery = $;
window.$ = $;

require('jquery-ui/ui/widgets/datepicker.js');
//require('jquery-ui/ui/widgets/accordion.js');
require('jquery-ui/ui/widgets/autocomplete.js');
require('jquery-ui/ui/widgets/tabs.js');

require('bootstrap');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js');



require("./jqExtensions.js");
require("./accordian.js");
require("./datepicker.js");
require("./calendar.js");

import dt from 'datatables.net'
window.dt = dt;

require("./entites.js");
require("./recherches.js");

