$("#accordian input[checked]").closest("li").addClass("active");
$("#accordian input").click(function(event) {
    var link = $(event.target);
    var closest_ul = link.closest("ul");
    //var parallel_active_links = closest_ul.find(".active");
    var closest_li = link.closest("li");
    var link_status = closest_li.hasClass("active");
    var count = 0;
    closest_li.find("ul").slideUp(function() {
    if (++count == closest_li.find("ul").length){
        closest_li.removeClass("active");
        closest_li.find("li").removeClass("active");
        closest_li.find("ul").find("input").prop("checked", false);
    }
    });
    if (!link_status) {
    closest_li.children("ul").slideDown();
    closest_li.addClass("active");
    } 
});
$(':checkbox[readonly]').click(function(){
    return false;
});


