$(document).ready(function() {
    // you may need to change this code if you are not using Bootstrap Datepicker
    //var SelectedDates = {};
    
    $('.js-datepicker-couleur').datepicker({
        language: 'fr-FR',
        format: 'dd/mm/yyyy',
        daysOfWeekDisabled: [0,6],
        startDate: '+1d',
        endDate: '+6m',
        orientation: 'top',
        autocomplete: 'off',
        beforeShowDay: function(date) {
            if(calendrier){
                var couleur = calendrier[date];
                if (couleur) {
                    if(couleur == "rouge"){
                        return false;
                    } else {
                        return couleur;
                    }
                    
                }
                else {
                    return false;
                }
            } else {
                return false;
            }
        }
    });
    
    $('.js-datepicker').datepicker({
        language: 'fr-FR',
        format: 'dd/mm/yyyy',
        daysOfWeekDisabled: [0,6],
        orientation: 'bottom',
        autocomplete: 'off',
    });
    /*
    $( ".accordion" ).accordion({
        collapsible: true,
    });
    */
    
    $('#datepicker_mobile').datepicker({
        language: 'fr-FR',
        format: 'dd/mm/yyyy',
        daysOfWeekDisabled: [0,6],
        startDate: '+1d',
        endDate: '+6m',
        beforeShowDay: function(date) {
            if(calendrier){
                var couleur = calendrier[date];
                if (couleur) {
                    if(couleur == "rouge"){
                        return false;
                    } else {
                        return couleur;
                    }
                    
                }
                else {
                    return false;
                }
            } else {
                return false;
            }
        }
        
    }).on('changeDate', function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).hide();
        $('.js-datepicker-couleur-mobile').val(new Intl.DateTimeFormat('fr-FR').format(e.date)).show();
        $( "form" ).first().submit();
    });

    $('.js-datepicker-couleur-mobile').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).val("").hide();
        $('#datepicker_mobile').show();
    });

    if(document.getElementById('datepicker_mobile') instanceof Object){
        if($('.js-datepicker-couleur-mobile').val() == ""){
            $('.js-datepicker-couleur-mobile').hide();
            $('#datepicker_mobile').show();
        } else {
            $('.js-datepicker-couleur-mobile').show();
            $('#datepicker_mobile').hide();
        }
    }

});