    document.addEventListener('DOMContentLoaded', () => {
        var calendarEl = document.getElementById('calendar-holder');
        if(calendarEl != undefined)
        {
            var calendar = new FullCalendar.Calendar(calendarEl, {
                locale:'fr',
                defaultView: 'dayGridMonth',
                editable: true,
                buttonText:{
                    today:'maintenant',
                    month:'mois',
                    week:'semaine',
                    day:'jour'
                },
                eventSources: [
                    {
                        url: "/fc-load-events",
                        method: "POST",
                        extraParams: {
                            filters: JSON.stringify({})
                        },
                        failure: () => {
                            // alert("There was an error while fetching FullCalendar!");
                        },
                    },
                ],
                header: {
                    left: 'prev,next today custom1',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                },
                plugins: [ 'interaction', 'dayGrid', 'timeGrid' ], // https://fullcalendar.io/docs/plugin-index
                timeZone: 'UTC',
                customButtons: {
                    custom1: {
                      text: 'custom 1',
                      click: function() {
                        alert('clicked custom button 1!');
                      }
                    },
                    custom2: {
                      text: 'custom 2',
                      click: function() {
                        alert('clicked custom button 2!');
                      }
                    }
                }
            });
            calendar.render();
        }
    });
